:root {
    --color-detalle: rgb(0, 159, 152);
    --color-secundario: #54595f;
    --color-texto: #7a7a7a;
    --color-fondo-texto: rgb(237,237,237);
    --color-text-sec:rgb(42, 48, 47);
    --color-text-typewrite: white;
    --color-back-empresa: rgb(43, 60, 117);
    --color-text-empresa: white;
    --color-textos-mag: rgb(0, 159, 152);
    --color-detalles-mag: rgb(0, 159, 152);
    --color-contraste: rgb(180, 0, 0);
}

/*Común----------------------------------*/
body {
    font-family: "Nunito",Sans-serif;
    margin: 0;
}

#logoWeb {
    max-width: 250px; 
}

main {
    color: var(--color-texto);
    font-weight: 400;
    line-height: 1.5rem;
    width: 80%;
    max-width: 1000px;
    margin: 0 auto;
}
.VisibilityHidden{
    visibility: hidden;
    position: absolute;
}

#imagenPortadaProd img
{
    width: 100%;
    max-height: 550px;
    object-fit: cover;
    margin-top: 100px;
}

a svg {
    stroke: white;
    stroke-width: 2px;
    fill: transparent;
}


/*Navegacion----------------------------------*/

.MenuSuperior{
    position: fixed;
    top: 0;
    background-color: white;
    z-index: 999;
    width: 100%;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0); /* Sin sombra inicial */
    transition: box-shadow 0.3s ease; /* Transición suave para la sombra */
}
.MenuSuperior.scrolled {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Sombra al hacer scroll */
}

#LogoYNav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100px;
    width: 80%;
    max-width: 1000px;
    margin-right: auto;
    margin-left: auto;
    color: var(--color-text-sec);
    font-size: 0.9rem;
}

#divNav{
    display: flex;
    align-items: center;
}
header nav {
    display: flex;
    align-items: center;
    justify-content: space-around;
}

header nav ul {
    list-style: none;
    padding: 0;
    display: flex;
    justify-content: center;
}

#navContent li, #navLang li{
    display: flex;
    align-items: center;
    justify-content: space-around;
}

#navContent li a {
    padding-left: 15px;
    padding-right: 15px;
}
#navLang li a {
    padding-left: 6px;
    padding-right: 6px;
}
#navLang {
    border-left: 1px solid var(--color-secundario); /* Borde vertical sólido y delgado */
    padding-left: 5px; /* Espacio entre el borde y el contenido */
    height: 30px;
}

header nav ul li a {
    text-decoration: none;
    color: #333; /* Color de texto base */
    padding-top: 38px;
    padding-bottom: 38px;
}

#navContent li a:hover, #navLang li a:hover, .NavItemSelected{
    color: var(--color-detalle);
}

#navContent  li a:hover, .NavItemSelected{
    border-bottom: 5px solid var(--color-detalle); 
    transition: border-bottom 0.2s; 
    
}

header nav ul li a:visited {
    text-decoration: none;
    color: inherit;
}

.dropdown {
    position: relative; 
}

.dropdown-content {
    display: none;
    position: absolute;
    top: 100%;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    z-index: 1;
    opacity: 0; /* Establece la opacidad inicial en 0 para el desvanecimiento */
    transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out; /* Agrega una transición de opacidad */
}
.dropdown::after {
    content: '\25BC'; /* Carácter Unicode de la flecha hacia abajo */
    display: inline-block; /* Hacer que la flecha sea en línea con el texto */
    margin-left: 7px; /* Agregar margen para separar la flecha del texto */
    font-size: 0.6rem;
    color: #333; /* Color de la flecha */
}

.dropdown-content li {
    height: 2.5rem;
}
.dropdown-content li a{
    padding-bottom: 5px;
    padding-top: 0px;
}

#mobileMenuBtn{
    visibility:hidden;
    /* margin-right: 10px; */
    background-color: white;
    border: none;
    font-size: 2rem;
    color: var(--color-secundario);
}

/* Estilos para el botón de la cruz (inicialmente oculto) */
#mobileMenuBtn .close-icon {
    display: none;
    font-size: 24px;
    cursor: pointer;
}

#navLangMov{
    visibility: hidden;
    width: 0;
}
/* Estilos para dispositivos de pantalla pequeña (por ejemplo, anchura máxima de 768px) */
@media screen and (max-width: 768px) {
    /* Oculta el menú principal en dispositivos móviles */

    #divNav {
        display: none;
    }
    /* Muestra el botón de hamburguesa */
    #mobileMenuBtn {
        display: block;
        cursor: pointer;
        visibility: visible;
    }

        /* Estilos para el menú desplegable */
        #divNav {
            display: none;
            position: absolute;
            top: 100px; /* Ajusta la posición según tu diseño */
            left: 0;
            width: 100%;
            background-color: #fff;
            z-index: 1;
            margin: 0;
        }
    
        /* Estilos para los elementos de la lista en el menú desplegable */
        #navContent ul {
            list-style: none;
            padding: 0;
            margin: 0;
        }
        #navContent li a {
            padding: 15px;
        }
        #navContent li {
            margin: 0px;
        }

    #LogoYNav {
        /* margin-right: auto;
        margin-left: auto;
        position: relative; */
        justify-content: space-around;
        width: 100vw;
    }

    /* #LogoYNav a{
        padding-left: 50px;
    } */

    .MenuSuperior {
        width: 100vw;
    }
    #navLang{
        visibility: hidden;
        width: 0%;
    }
    #navLangMov{
        visibility: visible;
        width: 100%;
        justify-content: center;
        
    }
    #navLangMov a {
        padding: 10px;
    }
        
}

/*footer----------------------------------*/

footer {  
    padding-top: 5rem;
    height: 400px;
    background-color: rgba(216, 216, 212);
    color: var(--color-secundario);
    font-size: 0.9rem;
    font-weight: 500;
}

footer div:first-child{
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 70%;
    width: 80%;
    max-width: 900px;
    margin: auto;
    
}
#rightDetFooter{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}
footer > p {
    border-top: 1px dotted var(--color-secundario);
    padding-top: 3rem;
    width: 80%;
    max-width: 900px;
    margin: auto; 
    font-size: 0.7rem;
}

footer nav {
    display: flex;
    align-items: center;
    justify-content: space-around;
}

footer nav ul {
    list-style: none;
    padding: 0;
    display: flex;
    justify-content: center;
}

footer li a {
    padding-left: 15px;
    padding-right: 15px;
}

footer a {
    text-decoration: none;
    color: inherit;
}

footer a:visited{
    text-decoration: none;
}
#redesFooter {
    display: flex;
    flex-direction: row;
    height: 80px;
    width: 100px;
    justify-content: space-around;
    align-items: center;
}
#redesFooter svg {
    width: 20px;
    fill: var(--color-secundario);
    /* margin-left: 20px; */
}
@media screen and (max-width: 768px) {
    footer nav {
        visibility: hidden;
        height: 0;
        width: 0; 
    }
    footer div:first-child{
        flex-direction: column;
        justify-content: center;
    }
    footer p {
        text-align: center;
    }
}
/*Animnacion aparecer----------------------------------*/

.animacionAparecer {
    opacity: 0;
    transform: translateY(300px);
    transition: opacity 1s, transform 1s;
}

.animacionAparecer.aparecerVisible {
    opacity: 1;
    transform: translateY(0);
}

.aparecerHidden {
    visibility: hidden;
}


/*Inicio----------------------------------*/

.Inicio header { 
    margin-bottom: 5rem;
}

#videoInicio{
    width: 100%;
    max-height: 500px;
    object-fit: cover;
    margin-top: 100px;
}

.Inicio main {
    max-width: 900px;
}

.duplaImgConInicio
{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.duplaContenidoInicio{
    margin-right: 50px;
}
.duplaContenidoInicio h2{
    font-weight:500;
    color:var(--color-detalle); 
    font-size: 2rem;
    font-family: "Roboto Slab",Sans-serif;
}
.duplaImgConInicio img {
    max-width: 70vw;
}

.typewriterText {
    text-align: center;
    padding: 50px;
    font-size: 24px;
    position:absolute;
    top: 250px; /* Ajusta la posición vertical según tus preferencias */
    left: 20%; /* Ajusta la posición horizontal según tus preferencias */
}

#typewriterTextJS {
    overflow: hidden; /* Evita que el texto desborde el contenedor */
    border-right: .05em solid white; /* Simula el cursor de escritura */
    white-space: nowrap; /* Hace que el texto se escriba en una línea */
    margin: 0 auto; /* Centra el texto horizontalmente */
    color: var(--color-text-typewrite);
    font-family: "Roboto Slab",Sans-serif;
    font-weight: 400;font-size: 3rem;
}

/* Estilos para dispositivos de pantalla pequeña (por ejemplo, anchura máxima de 768px) */
@media screen and (max-width: 768px) {

    #videoInicio{
        margin-top: 100px;
        height:300px;
    }

    .typewriterText {
        top: 240px; /* Ajusta la posición vertical según tus preferencias */
        /* left: 5%; Ajusta la posición horizontal según tus preferencias */
        left: 0;
        padding: 0;
    }

    #typewriterTextJS {
        font-size: 6vw;
        width: 100vw;
        overflow: hidden;
    }
    .duplaImgConInicio
    {
        flex-direction: column;
    }
    .Inicio header {
        margin-bottom: 1rem;
    }
    .duplaContenidoInicio{
        text-align: center;
        margin-right: 0;
    }
    .cambiarOrdenDupla:nth-child(1){
        order: 2;
    }
    .duplaContenidoInicio h2{
        line-height: 2rem;
    }
}

/*Empresa----------------------------------*/

.Empresa main {
    padding-top: 150px;
    color: var(--color-text-empresa);
    line-height: 2rem;
}

.Empresa{
    background-color: var(--color-back-empresa);

}

.Empresa main div:not(.miniDuplaEmpresa) {
    font-weight: 500;
    font-size: 1.2rem;
    padding-bottom: 3rem;
}
.megaDuplaEmpresa{
    padding-top: 3rem;
    padding-bottom: 3rem;
    border-top: 1px solid var(--color-textos-mag);
    border-bottom: 1px solid var(--color-textos-mag);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.miniDuplaEmpresa{
    margin: 0 20px 0 20px;
}
.queHacemosEmpresa{
    padding-bottom: 100px !important;
}
.Empresa h2, .Empresa h3,.Empresa h4{
    font-family: "Roboto Slab",Sans-serif;
    color: var(--color-textos-mag);
    font-size: 3rem;
    font-weight: 500;
}
.Empresa h2, h4{
    font-size: 2rem;
}
@media screen and (max-width: 768px) {

 .megaDuplaEmpresa{
    flex-direction: column;
 }
 .queHacemosEmpresa h3 {
    line-height: 3rem;
 }
}
 
/*Productos----------------------------------*/

.Productos main
{
    width: 90%;
    max-width: 1200px;
}


.Productos header { 
    margin-bottom: 5rem;
}

.Productos header p {
    position:absolute;
    top: 250px; /* Ajusta la posición vertical según tus preferencias */
    text-align: center;
    font-family: "Roboto Slab",Sans-serif;
    color: white;
    font-size: 3rem;
    width: 100%;
}

.Productos main>p{
    color: var(--color-textos-mag);
    text-align:center;
    margin: 0 auto;
    margin-bottom: 5rem;
    font-weight: 400;
    font-size: 1.5rem;
    line-height: 2.2rem;
    width: 80%;
}

.product-link{
    color: inherit;
    text-decoration: none;
}

.Productos .product-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr)); /* Columnas automáticas con un mínimo de 200px de ancho */
    gap: 10px; /* Espacio entre los productos */
    margin-bottom: 10rem;
  }
  
.Productos .product {
    border: 1px solid var(--color-detalles-mag);
    padding: 10px;
    text-align: center;
    overflow: hidden; /* Evita que el overlay se expanda fuera del producto */
    position: relative;
    height: 100%;
  }
  
.Productos .product img {
    max-width: 90%;
    height: 300px;
    object-fit: contain;
  }
  
.Productos .product h2 {
    font-size: 1.5rem;
    margin: 10px 0;
    color: var(--color-detalles-mag);
  }

.Productos .product p {
    font-size: 0.9rem;
    padding: 0 10px 0 10px;
  }

  .product-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: var(--color-detalles-mag); /* Fondo semi-transparente */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    opacity: 0; /* Oculta inicialmente */
    transition: opacity 0.8s, background-color 0.8s; /* Animación de transición */
}

.product-overlay p {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    color: white;
}

.info-button {
    background-color: var(--color-detalles-mag);
    color: white;
    border: 2px solid white;
    padding: 10px 20px;
    cursor: pointer;
    opacity: 0; /* Oculta inicialmente */
    transition: opacity 0.3s;
    border-radius: 10px;
}

.product:hover .product-overlay {
    opacity: 1; /* Muestra el overlay al pasar el ratón por encima */
}

.product:hover .info-button {
    opacity: 1; /* Muestra el botón al pasar el ratón por encima */
}

@media screen and (max-width: 768px) {
    .Productos header p {
        top: 120px;
        font-size: 8vw;
    }
    .Productos header {
        margin-bottom: 2rem;
    }
    .Productos main > p {
        line-height: 1.5rem;
        font-size: 1rem;
        margin: 0 auto 3rem;
    }
    .Productos .product {
        padding: 0;
    }
}


/*Contacto----------------------------------*/

.apartadoContacto {
    color: var(--color-texto);
    text-align:center;
    margin: 0 auto;
    margin-top: 5rem;
}

.Contacto h2 {
    color: var(--color-textos-mag);
}

#nuestroDatos p {
    margin-top: 1px;
    margin-bottom: 1px;
}
#nuestroDatos a {
    text-decoration: none;
    color: inherit;
}
#nuestroDatos{
    width: 80%;
    border-bottom: 1px solid var(--color-detalles-mag);
    padding-bottom: 3rem;
}
.negritaContacto{
    color: black;
    margin-top: 2rem !important;
}

/* Estilos para el diseño del formulario */
label.required::after {
    content: " *";
    color: red;
}

.form-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    width: 60%;
    margin: 0 auto;
    margin-bottom: 5px;
}

.form-group label {
    text-align: left;
}

.form-group input,
.form-group textarea,
.form-group select {
    padding: 5px;
}

.form-group textarea {
    height: 150px; /* Altura ajustada según tu preferencia */
}

.form-group.checkbox {
    flex-direction: row;
    align-items: center;
}

.form-group.checkbox label {
    font-weight: normal;
}

button[type="submit"] {
    background-color: var(--color-detalles-mag);
    color: #fff;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 5px;
}

#formularioContacto{
 margin-bottom: 5rem;
}

@media screen and (max-width: 768px) {
    .form-group {
        width: 80vw;
    }
}

/*Ficha productos----------------------------------*/

.FichaProducto h1 {
    color: var(--color-textos-mag);
    text-align:center;
    margin: 0 auto;
    font-size: 3rem;
    line-height: 3rem;
}
.FichaProducto h2
{
    width: 100%;
    text-align: center;
    font-size: 0.9rem;
}

.FichaProducto main {
    margin-top: 10rem;
}

.duplaSlideDesc {
    margin-top: 4rem;
    display: flex;
    flex-direction: row;
    gap: 50px;
    align-items: center;
    margin-bottom: 5rem;
}
.duplaSlideDesc > * {
    flex-basis: 0;
    flex-grow: 1;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.swiper-pagination-bullet {
    background: var(--color-detalles-mag) !important;
}

.tabs {
    display: flex;
    flex-wrap: wrap; /* Permite que los botones se envuelvan cuando no caben en una línea */
}
/* Estilos para las pestañas y el contenido */
.tab-button {
    background-color: var(--color-secundario);
    color: #fff;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 2px;
    flex-grow: 1;
}

.tab-button.active {
    background-color: var(--color-detalles-mag);
}

.tab-content {
    display: none;
    padding: 20px;
    /* border: 1px solid #ccc; */
    margin-bottom: 2rem;
}

.tab-content.show {
    display: block;
}

/* Estilo Datos tecnicos */
td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6;
}

tr td{
    width: 96px;
}

tr td:first-child{
    width: 60px;
    font-weight: bold;
}

.duplaTabs {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.duplaTabs > * {
    width: 50%;
}

.duplaTabs .swiper {
    width: 70%;
}

.videoEmbed{
    display: flex;
    justify-content: center; /* Centrar horizontalmente */
    align-items: center; /* Centrar verticalmente */
}


.downloadButton {
    text-decoration: none;
    text-align: center;
    background-color: var(--color-detalles-mag);
    color: #fff;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    border-radius: 3px;
}
.contenedorLinkDescarga
{
    width: 100%;
    display: flex;
    justify-content: center;
}

.downloadButton:hover {
    background-color: var(--color-secundario);
}


#pictogramasProd {
    display: flex;
    flex-direction: row;
    gap: 50px;
    align-items: flex-start;
    margin-bottom: 5rem;
}
#pictogramasProd > * {
    flex-basis: 0;
    flex-grow: 1;
}
#pictogramasProd p {
    text-align: center;
}
.FichaProducto table{
    width: 100%;
    height: 62px;
}

.swiper {
	width: 50%;
	/* height: 30%; */
}

@media screen and (max-width: 768px) {
    .swiper-slide img {
        width: 70vw;
        height: 300px;
        object-fit: contain;
      }
    .duplaSlideDesc {
        flex-direction: column;
        margin-top: 2rem;
        margin-bottom: 1rem;
        gap: 0;
    }
    .duplaSlideDesc > * {
        flex-grow: 0;
        flex-basis:auto;
    }
    .swiper {
        width: 80vw;
        height: 300px;
    }
    #pictogramasProd{
        display: grid;
        justify-items: center;
        gap: 0;
    }
    #pictogramasProd img{
        width: 50vw;
        margin: 0 auto;
    }
    #pictogramasProd div {
        display: flex;
        justify-content: center; /* Centrar horizontalmente */
        align-items: center; /* Centrar verticalmente */
        flex-direction: column;
    }
    #pictogramasProd p {
        margin: auto 0 auto 0;
    }
    .videoEmbed{
        width: 50vw;
    }
    .duplaTabs{
        flex-direction: column;
    }
    .duplaTabs > * {
        width: 100%;
    }
    .duplaTabs .swiper {
        width: 100%;
    }
    .duplaTabs img{
        width: 100%;
    }
    .tab-content
    {
        background-color: var(--color-fondo-texto);
    }
    .FichaProducto h1 {
        color: var(--color-textos-mag);
        text-align:center;
        margin: 0 auto;
        font-size: 2rem;
        line-height: 2rem;
        overflow: hidden;
    }
    .FichaProducto main {
        margin-top: 7rem;
    }
    
    .duplaSlideDesc {
        margin-top: 0;
    }
}

/*Blog----------------------------------*/
.Blog main > h2 {
    width: 100%;
    align-items: center;
    text-align: center;
    font-size: 2rem;
    color: var(--color-textos-mag);
    margin-bottom: 3rem;
}

.Blog main{
    margin-top: 10rem;
}

.Blog .blog-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, 300px); /* Columnas automáticas con un mínimo de 200px de ancho */
    gap: 20px; /* Espacio entre los productos */
    margin-bottom: 10rem;
  }
  
.Blog .entradaBlog {
    text-align: center;
    overflow: hidden; /* Evita que el overlay se expanda fuera del producto */
    position: relative;
    height: 100%;
  }

.Blog .entradaBlog > *:not(img):not(.blog-overlay)
{
    text-align: left;
    width: 85%;
    align-items: center;
    margin: 0 auto;
}
.blog-link{
    color: inherit;
    text-decoration: none;
    background-color: rgb(247, 247, 247);
}

.entradaBlog img {
    max-width: 90%;
    height: 180px;
    object-fit: contain;
    margin-bottom: 0;
  }
  
.entradaBlog h2 {
    font-size: 1.5rem;
    margin: 10px 0;
    color: var(--color-detalles-mag);
  }

.entradaBlog p {
    font-size: 0.9rem;
}
.TipoEntradaBlog {
    color: var(--color-contraste);
    font-weight: 600;
  }
.FechaEntradaBlog{
    margin-bottom: 1rem !important;
}
  .entradaBlog h3 {
    font-size: 1rem;
    color: var(--color-text-sec);
    margin: 0 10px 0 10px;

  }

.entradaBlog-button {
    background-color: var(--color-detalles-mag);
    color: white;
    border: 2px solid white;
    padding: 10px 20px;
    cursor: pointer;
    opacity: 0; /* Oculta inicialmente */
    transition: opacity 0.3s;
    border-radius: 10px;
}

.entradaBlog:hover .entradaBlog-overlay {
    opacity: 1; /* Muestra el overlay al pasar el ratón por encima */
}

.entradaBlog:hover .entradaBlog-button {
    opacity: 1; /* Muestra el botón al pasar el ratón por encima */
}


.blog-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--color-detalles-mag); /* Fondo semi-transparente */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: 0; /* Oculta inicialmente */
  transition: opacity 0.8s, background-color 0.8s; /* Animación de transición */
}
.blog-overlay p {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    color: white;
}

.blog-button {
    background-color: var(--color-detalles-mag);
    color: white;
    border: 2px solid white;
    padding: 10px 20px;
    cursor: pointer;
    opacity: 0; /* Oculta inicialmente */
    transition: opacity 0.3s;
    border-radius: 10px;
}

.entradaBlog:hover .blog-overlay {
    opacity: 1; /* Muestra el overlay al pasar el ratón por encima */
}

.entradaBlog:hover .blog-button {
    opacity: 1; /* Muestra el botón al pasar el ratón por encima */
}

/*EntradaBlog----------------------------------*/

.EntradaBlog main {
    margin-top: 9rem;
    margin-bottom: 8rem;
}

.EntradaBlog main a {
    text-decoration: none;
    font-weight: bold;
    color:var(--color-textos-mag) !important
}

.EntradaBlog main a:visited {
    color: inherit;
}
.EntradaBlog p {
    text-align: justify;
}
.EntradaBlog h2{
    font-family: "Roboto Slab",Sans-serif;
    font-size: 3rem;
    color: var(--color-textos-mag);
    line-height: 2.5rem;
}
.EntradaBlog h3{
    font-family: "Roboto Slab",Sans-serif;
    font-size: 2rem;
    color: var(--color-textos-mag);
    line-height: 2.5rem;
}

.EntradaBlog figure{
    margin: 0px;
}
.EntradaBlog figcaption{
    font-size: 0.9rem;
    width: 100%;
    text-align: center;
}
.imagenBlog img{
    width: 100%;
}

.EntradaBlog  blockquote {
    font-style: italic;
    font-weight: bold;
    margin: 0 0 2rem 2rem;
}

@media screen and (max-width: 768px) {
    .EntradaBlog h2{
        font-size: 1.5rem;
    }
}

/*cookies----------------------------------*/

.cookies main {
    margin-top: 7rem;
}

.boldText{
    font-weight: bold;
}


/*thanks----------------------------------*/

.Thanks h2{
    color: var(--color-textos-mag);
    margin-top: 5rem;
    line-height: 2rem;
}
.Thanks main p{
    margin-bottom: 2rem;
}

.Thanks main a{
    color: white;
    text-decoration: none;
    padding: 1rem;
    background-color: var(--color-detalles-mag);
    border-radius: 5px;
}

.Thanks footer{
    margin-top: 5rem;
}

/* aviso de cookies------------------------*/

.cookie-notice {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100vw;
    background: var(--color-texto);
    color: white;
    text-align: center;
    padding: 10px;
    display: none;
    box-shadow: 0px 2px 4px 3px rgba(0, 0, 0, 0.2);
}

.cookie-notice p {
    font-size: smaller;
    text-align: center;
    padding-left: 5%;
    padding-right: 5%;
}
.cookie-accept {
    background: var(--color-detalles-mag);
    color: white;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
    border-radius: 5px;
}

.cookie-accept:hover {
    background: var(--color-secundario);
}

.cookie-reject {
    background: var(--color-secundario);
    color: white;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
    border-radius: 5px;
}

.cookie-accept:hover {
    background: var(--color-secundario);
}

.cookie-more-info {
    font-size: smaller;
    color: white;
    text-decoration: none;
    margin-left: 10px;
}

.cookie-more-info:hover {
    text-decoration: underline;
}








