:root {
  --color-detalle: #009f98;
  --color-secundario: #54595f;
  --color-texto: #7a7a7a;
  --color-fondo-texto: #ededed;
  --color-text-sec: #2a302f;
  --color-text-typewrite: white;
  --color-back-empresa: #2b3c75;
  --color-text-empresa: white;
  --color-textos-mag: #009f98;
  --color-detalles-mag: #009f98;
  --color-contraste: #b40000;
}

body {
  margin: 0;
  font-family: Nunito, sans-serif;
}

#logoWeb {
  max-width: 250px;
}

main {
  color: var(--color-texto);
  width: 80%;
  max-width: 1000px;
  margin: 0 auto;
  font-weight: 400;
  line-height: 1.5rem;
}

.VisibilityHidden {
  visibility: hidden;
  position: absolute;
}

#imagenPortadaProd img {
  width: 100%;
  max-height: 550px;
  object-fit: cover;
  margin-top: 100px;
}

a svg {
  stroke: #fff;
  stroke-width: 2px;
  fill: #0000;
}

.MenuSuperior {
  z-index: 999;
  width: 100%;
  background-color: #fff;
  transition: box-shadow .3s;
  position: fixed;
  top: 0;
  box-shadow: 0 2px 4px #0000;
}

.MenuSuperior.scrolled {
  box-shadow: 0 2px 4px #0003;
}

#LogoYNav {
  height: 100px;
  width: 80%;
  max-width: 1000px;
  color: var(--color-text-sec);
  justify-content: space-between;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  font-size: .9rem;
  display: flex;
}

#divNav {
  align-items: center;
  display: flex;
}

header nav {
  justify-content: space-around;
  align-items: center;
  display: flex;
}

header nav ul {
  justify-content: center;
  padding: 0;
  list-style: none;
  display: flex;
}

#navContent li, #navLang li {
  justify-content: space-around;
  align-items: center;
  display: flex;
}

#navContent li a {
  padding-left: 15px;
  padding-right: 15px;
}

#navLang li a {
  padding-left: 6px;
  padding-right: 6px;
}

#navLang {
  border-left: 1px solid var(--color-secundario);
  height: 30px;
  padding-left: 5px;
}

header nav ul li a {
  color: #333;
  padding-top: 38px;
  padding-bottom: 38px;
  text-decoration: none;
}

#navContent li a:hover, #navLang li a:hover, .NavItemSelected {
  color: var(--color-detalle);
}

#navContent li a:hover, .NavItemSelected {
  border-bottom: 5px solid var(--color-detalle);
  transition: border-bottom .2s;
}

header nav ul li a:visited {
  color: inherit;
  text-decoration: none;
}

.dropdown {
  position: relative;
}

.dropdown-content {
  z-index: 1;
  opacity: 0;
  background-color: #fff;
  transition: transform .3s ease-in-out, opacity .3s ease-in-out;
  display: none;
  position: absolute;
  top: 100%;
  box-shadow: 0 2px 4px #0003;
}

.dropdown:after {
  content: "▼";
  color: #333;
  margin-left: 7px;
  font-size: .6rem;
  display: inline-block;
}

.dropdown-content li {
  height: 2.5rem;
}

.dropdown-content li a {
  padding-top: 0;
  padding-bottom: 5px;
}

#mobileMenuBtn {
  visibility: hidden;
  color: var(--color-secundario);
  background-color: #fff;
  border: none;
  font-size: 2rem;
}

#mobileMenuBtn .close-icon {
  cursor: pointer;
  font-size: 24px;
  display: none;
}

#navLangMov {
  visibility: hidden;
  width: 0;
}

@media screen and (max-width: 768px) {
  #divNav {
    display: none;
  }

  #mobileMenuBtn {
    cursor: pointer;
    visibility: visible;
    display: block;
  }

  #divNav {
    width: 100%;
    z-index: 1;
    background-color: #fff;
    margin: 0;
    display: none;
    position: absolute;
    top: 100px;
    left: 0;
  }

  #navContent ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  #navContent li a {
    padding: 15px;
  }

  #navContent li {
    margin: 0;
  }

  #LogoYNav {
    width: 100vw;
    justify-content: space-around;
  }

  .MenuSuperior {
    width: 100vw;
  }

  #navLang {
    visibility: hidden;
    width: 0%;
  }

  #navLangMov {
    visibility: visible;
    width: 100%;
    justify-content: center;
  }

  #navLangMov a {
    padding: 10px;
  }
}

footer {
  height: 400px;
  color: var(--color-secundario);
  background-color: #d8d8d4;
  padding-top: 5rem;
  font-size: .9rem;
  font-weight: 500;
}

footer div:first-child {
  height: 70%;
  width: 80%;
  max-width: 900px;
  justify-content: space-between;
  align-items: center;
  margin: auto;
  display: flex;
}

#rightDetFooter {
  flex-direction: column;
  align-items: flex-end;
  display: flex;
}

footer > p {
  border-top: 1px dotted var(--color-secundario);
  width: 80%;
  max-width: 900px;
  margin: auto;
  padding-top: 3rem;
  font-size: .7rem;
}

footer nav {
  justify-content: space-around;
  align-items: center;
  display: flex;
}

footer nav ul {
  justify-content: center;
  padding: 0;
  list-style: none;
  display: flex;
}

footer li a {
  padding-left: 15px;
  padding-right: 15px;
}

footer a {
  color: inherit;
  text-decoration: none;
}

footer a:visited {
  text-decoration: none;
}

#redesFooter {
  height: 80px;
  width: 100px;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  display: flex;
}

#redesFooter svg {
  width: 20px;
  fill: var(--color-secundario);
}

@media screen and (max-width: 768px) {
  footer nav {
    visibility: hidden;
    height: 0;
    width: 0;
  }

  footer div:first-child {
    flex-direction: column;
    justify-content: center;
  }

  footer p {
    text-align: center;
  }
}

.animacionAparecer {
  opacity: 0;
  transition: opacity 1s, transform 1s;
  transform: translateY(300px);
}

.animacionAparecer.aparecerVisible {
  opacity: 1;
  transform: translateY(0);
}

.aparecerHidden {
  visibility: hidden;
}

.Inicio header {
  margin-bottom: 5rem;
}

#videoInicio {
  width: 100%;
  max-height: 500px;
  object-fit: cover;
  margin-top: 100px;
}

.Inicio main {
  max-width: 900px;
}

.duplaImgConInicio {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.duplaContenidoInicio {
  margin-right: 50px;
}

.duplaContenidoInicio h2 {
  color: var(--color-detalle);
  font-family: Roboto Slab, sans-serif;
  font-size: 2rem;
  font-weight: 500;
}

.duplaImgConInicio img {
  max-width: 70vw;
}

.typewriterText {
  text-align: center;
  padding: 50px;
  font-size: 24px;
  position: absolute;
  top: 250px;
  left: 20%;
}

#typewriterTextJS {
  white-space: nowrap;
  color: var(--color-text-typewrite);
  border-right: .05em solid #fff;
  margin: 0 auto;
  font-family: Roboto Slab, sans-serif;
  font-size: 3rem;
  font-weight: 400;
  overflow: hidden;
}

@media screen and (max-width: 768px) {
  #videoInicio {
    height: 300px;
    margin-top: 100px;
  }

  .typewriterText {
    padding: 0;
    top: 240px;
    left: 0;
  }

  #typewriterTextJS {
    width: 100vw;
    font-size: 6vw;
    overflow: hidden;
  }

  .duplaImgConInicio {
    flex-direction: column;
  }

  .Inicio header {
    margin-bottom: 1rem;
  }

  .duplaContenidoInicio {
    text-align: center;
    margin-right: 0;
  }

  .cambiarOrdenDupla:first-child {
    order: 2;
  }

  .duplaContenidoInicio h2 {
    line-height: 2rem;
  }
}

.Empresa main {
  color: var(--color-text-empresa);
  padding-top: 150px;
  line-height: 2rem;
}

.Empresa {
  background-color: var(--color-back-empresa);
}

.Empresa main div:not(.miniDuplaEmpresa) {
  padding-bottom: 3rem;
  font-size: 1.2rem;
  font-weight: 500;
}

.megaDuplaEmpresa {
  border-top: 1px solid var(--color-textos-mag);
  border-bottom: 1px solid var(--color-textos-mag);
  flex-direction: row;
  justify-content: space-between;
  padding-top: 3rem;
  padding-bottom: 3rem;
  display: flex;
}

.miniDuplaEmpresa {
  margin: 0 20px;
}

.queHacemosEmpresa {
  padding-bottom: 100px !important;
}

.Empresa h2, .Empresa h3, .Empresa h4 {
  color: var(--color-textos-mag);
  font-family: Roboto Slab, sans-serif;
  font-size: 3rem;
  font-weight: 500;
}

.Empresa h2, h4 {
  font-size: 2rem;
}

@media screen and (max-width: 768px) {
  .megaDuplaEmpresa {
    flex-direction: column;
  }

  .queHacemosEmpresa h3 {
    line-height: 3rem;
  }
}

.Productos main {
  width: 90%;
  max-width: 1200px;
}

.Productos header {
  margin-bottom: 5rem;
}

.Productos header p {
  text-align: center;
  color: #fff;
  width: 100%;
  font-family: Roboto Slab, sans-serif;
  font-size: 3rem;
  position: absolute;
  top: 250px;
}

.Productos main > p {
  color: var(--color-textos-mag);
  text-align: center;
  width: 80%;
  margin: 0 auto 5rem;
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 2.2rem;
}

.product-link {
  color: inherit;
  text-decoration: none;
}

.Productos .product-grid {
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 10px;
  margin-bottom: 10rem;
  display: grid;
}

.Productos .product {
  border: 1px solid var(--color-detalles-mag);
  text-align: center;
  height: 100%;
  padding: 10px;
  position: relative;
  overflow: hidden;
}

.Productos .product img {
  max-width: 90%;
  height: 300px;
  object-fit: contain;
}

.Productos .product h2 {
  color: var(--color-detalles-mag);
  margin: 10px 0;
  font-size: 1.5rem;
}

.Productos .product p {
  padding: 0 10px;
  font-size: .9rem;
}

.product-overlay {
  width: 100%;
  height: 100%;
  background: var(--color-detalles-mag);
  opacity: 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: opacity .8s, background-color .8s;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.product-overlay p {
  width: 100%;
  height: 100%;
  color: #fff;
  justify-content: center;
  align-items: center;
  margin: 0;
  display: flex;
  position: absolute;
}

.info-button {
  background-color: var(--color-detalles-mag);
  color: #fff;
  cursor: pointer;
  opacity: 0;
  border: 2px solid #fff;
  border-radius: 10px;
  padding: 10px 20px;
  transition: opacity .3s;
}

.product:hover .product-overlay, .product:hover .info-button {
  opacity: 1;
}

@media screen and (max-width: 768px) {
  .Productos header p {
    font-size: 8vw;
    top: 120px;
  }

  .Productos header {
    margin-bottom: 2rem;
  }

  .Productos main > p {
    margin: 0 auto 3rem;
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .Productos .product {
    padding: 0;
  }
}

.apartadoContacto {
  color: var(--color-texto);
  text-align: center;
  margin: 5rem auto 0;
}

.Contacto h2 {
  color: var(--color-textos-mag);
}

#nuestroDatos p {
  margin-top: 1px;
  margin-bottom: 1px;
}

#nuestroDatos a {
  color: inherit;
  text-decoration: none;
}

#nuestroDatos {
  width: 80%;
  border-bottom: 1px solid var(--color-detalles-mag);
  padding-bottom: 3rem;
}

.negritaContacto {
  color: #000;
  margin-top: 2rem !important;
}

label.required:after {
  content: " *";
  color: red;
}

.form-group {
  width: 60%;
  flex-direction: column;
  margin: 0 auto 5px;
  display: flex;
}

.form-group label {
  text-align: left;
}

.form-group input, .form-group textarea, .form-group select {
  padding: 5px;
}

.form-group textarea {
  height: 150px;
}

.form-group.checkbox {
  flex-direction: row;
  align-items: center;
}

.form-group.checkbox label {
  font-weight: normal;
}

button[type="submit"] {
  background-color: var(--color-detalles-mag);
  color: #fff;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
}

#formularioContacto {
  margin-bottom: 5rem;
}

@media screen and (max-width: 768px) {
  .form-group {
    width: 80vw;
  }
}

.FichaProducto h1 {
  color: var(--color-textos-mag);
  text-align: center;
  margin: 0 auto;
  font-size: 3rem;
  line-height: 3rem;
}

.FichaProducto h2 {
  width: 100%;
  text-align: center;
  font-size: .9rem;
}

.FichaProducto main {
  margin-top: 10rem;
}

.duplaSlideDesc {
  flex-direction: row;
  align-items: center;
  gap: 50px;
  margin-top: 4rem;
  margin-bottom: 5rem;
  display: flex;
}

.duplaSlideDesc > * {
  flex-grow: 1;
  flex-basis: 0;
}

.swiper-slide img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
}

.swiper-pagination-bullet {
  background: var(--color-detalles-mag) !important;
}

.tabs {
  flex-wrap: wrap;
  display: flex;
}

.tab-button {
  background-color: var(--color-secundario);
  color: #fff;
  cursor: pointer;
  border: none;
  border-radius: 2px;
  flex-grow: 1;
  padding: 10px 20px;
}

.tab-button.active {
  background-color: var(--color-detalles-mag);
}

.tab-content {
  margin-bottom: 2rem;
  padding: 20px;
  display: none;
}

.tab-content.show {
  display: block;
}

td {
  vertical-align: top;
  border-top: 1px solid #dee2e6;
  padding: .75rem;
}

tr td {
  width: 96px;
}

tr td:first-child {
  width: 60px;
  font-weight: bold;
}

.duplaTabs {
  flex-direction: row;
  align-items: center;
  display: flex;
}

.duplaTabs > * {
  width: 50%;
}

.duplaTabs .swiper {
  width: 70%;
}

.videoEmbed {
  justify-content: center;
  align-items: center;
  display: flex;
}

.downloadButton {
  text-align: center;
  background-color: var(--color-detalles-mag);
  color: #fff;
  cursor: pointer;
  border: none;
  border-radius: 3px;
  padding: 10px 20px;
  text-decoration: none;
}

.contenedorLinkDescarga {
  width: 100%;
  justify-content: center;
  display: flex;
}

.downloadButton:hover {
  background-color: var(--color-secundario);
}

#pictogramasProd {
  flex-direction: row;
  align-items: flex-start;
  gap: 50px;
  margin-bottom: 5rem;
  display: flex;
}

#pictogramasProd > * {
  flex-grow: 1;
  flex-basis: 0;
}

#pictogramasProd p {
  text-align: center;
}

.FichaProducto table {
  width: 100%;
  height: 62px;
}

.swiper {
  width: 50%;
}

@media screen and (max-width: 768px) {
  .swiper-slide img {
    width: 70vw;
    height: 300px;
    object-fit: contain;
  }

  .duplaSlideDesc {
    flex-direction: column;
    gap: 0;
    margin-top: 2rem;
    margin-bottom: 1rem;
  }

  .duplaSlideDesc > * {
    flex-grow: 0;
    flex-basis: auto;
  }

  .swiper {
    width: 80vw;
    height: 300px;
  }

  #pictogramasProd {
    justify-items: center;
    gap: 0;
    display: grid;
  }

  #pictogramasProd img {
    width: 50vw;
    margin: 0 auto;
  }

  #pictogramasProd div {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    display: flex;
  }

  #pictogramasProd p {
    margin: auto 0;
  }

  .videoEmbed {
    width: 50vw;
  }

  .duplaTabs {
    flex-direction: column;
  }

  .duplaTabs > *, .duplaTabs .swiper, .duplaTabs img {
    width: 100%;
  }

  .tab-content {
    background-color: var(--color-fondo-texto);
  }

  .FichaProducto h1 {
    color: var(--color-textos-mag);
    text-align: center;
    margin: 0 auto;
    font-size: 2rem;
    line-height: 2rem;
    overflow: hidden;
  }

  .FichaProducto main {
    margin-top: 7rem;
  }

  .duplaSlideDesc {
    margin-top: 0;
  }
}

.Blog main > h2 {
  width: 100%;
  text-align: center;
  color: var(--color-textos-mag);
  align-items: center;
  margin-bottom: 3rem;
  font-size: 2rem;
}

.Blog main {
  margin-top: 10rem;
}

.Blog .blog-grid {
  grid-template-columns: repeat(auto-fit, 300px);
  gap: 20px;
  margin-bottom: 10rem;
  display: grid;
}

.Blog .entradaBlog {
  text-align: center;
  height: 100%;
  position: relative;
  overflow: hidden;
}

.Blog .entradaBlog > :not(img):not(.blog-overlay) {
  text-align: left;
  width: 85%;
  align-items: center;
  margin: 0 auto;
}

.blog-link {
  color: inherit;
  background-color: #f7f7f7;
  text-decoration: none;
}

.entradaBlog img {
  max-width: 90%;
  height: 180px;
  object-fit: contain;
  margin-bottom: 0;
}

.entradaBlog h2 {
  color: var(--color-detalles-mag);
  margin: 10px 0;
  font-size: 1.5rem;
}

.entradaBlog p {
  font-size: .9rem;
}

.TipoEntradaBlog {
  color: var(--color-contraste);
  font-weight: 600;
}

.FechaEntradaBlog {
  margin-bottom: 1rem !important;
}

.entradaBlog h3 {
  color: var(--color-text-sec);
  margin: 0 10px;
  font-size: 1rem;
}

.entradaBlog-button {
  background-color: var(--color-detalles-mag);
  color: #fff;
  cursor: pointer;
  opacity: 0;
  border: 2px solid #fff;
  border-radius: 10px;
  padding: 10px 20px;
  transition: opacity .3s;
}

.entradaBlog:hover .entradaBlog-overlay, .entradaBlog:hover .entradaBlog-button {
  opacity: 1;
}

.blog-overlay {
  width: 100%;
  height: 100%;
  background: var(--color-detalles-mag);
  opacity: 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: opacity .8s, background-color .8s;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.blog-overlay p {
  width: 100%;
  height: 100%;
  color: #fff;
  justify-content: center;
  align-items: center;
  margin: 0;
  display: flex;
  position: absolute;
}

.blog-button {
  background-color: var(--color-detalles-mag);
  color: #fff;
  cursor: pointer;
  opacity: 0;
  border: 2px solid #fff;
  border-radius: 10px;
  padding: 10px 20px;
  transition: opacity .3s;
}

.entradaBlog:hover .blog-overlay, .entradaBlog:hover .blog-button {
  opacity: 1;
}

.EntradaBlog main {
  margin-top: 9rem;
  margin-bottom: 8rem;
}

.EntradaBlog main a {
  font-weight: bold;
  text-decoration: none;
  color: var(--color-textos-mag) !important;
}

.EntradaBlog main a:visited {
  color: inherit;
}

.EntradaBlog p {
  text-align: justify;
}

.EntradaBlog h2 {
  color: var(--color-textos-mag);
  font-family: Roboto Slab, sans-serif;
  font-size: 3rem;
  line-height: 2.5rem;
}

.EntradaBlog h3 {
  color: var(--color-textos-mag);
  font-family: Roboto Slab, sans-serif;
  font-size: 2rem;
  line-height: 2.5rem;
}

.EntradaBlog figure {
  margin: 0;
}

.EntradaBlog figcaption {
  width: 100%;
  text-align: center;
  font-size: .9rem;
}

.imagenBlog img {
  width: 100%;
}

.EntradaBlog blockquote {
  margin: 0 0 2rem 2rem;
  font-style: italic;
  font-weight: bold;
}

@media screen and (max-width: 768px) {
  .EntradaBlog h2 {
    font-size: 1.5rem;
  }
}

.cookies main {
  margin-top: 7rem;
}

.boldText {
  font-weight: bold;
}

.Thanks h2 {
  color: var(--color-textos-mag);
  margin-top: 5rem;
  line-height: 2rem;
}

.Thanks main p {
  margin-bottom: 2rem;
}

.Thanks main a {
  color: #fff;
  background-color: var(--color-detalles-mag);
  border-radius: 5px;
  padding: 1rem;
  text-decoration: none;
}

.Thanks footer {
  margin-top: 5rem;
}

.cookie-notice {
  width: 100vw;
  background: var(--color-texto);
  color: #fff;
  text-align: center;
  padding: 10px;
  display: none;
  position: fixed;
  bottom: 0;
  left: 0;
  box-shadow: 0 2px 4px 3px #0003;
}

.cookie-notice p {
  text-align: center;
  padding-left: 5%;
  padding-right: 5%;
  font-size: smaller;
}

.cookie-accept {
  background: var(--color-detalles-mag);
  color: #fff;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
}

.cookie-reject {
  background: var(--color-secundario);
  color: #fff;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
}

.cookie-accept:hover {
  background: var(--color-secundario);
}

.cookie-more-info {
  color: #fff;
  margin-left: 10px;
  font-size: smaller;
  text-decoration: none;
}

.cookie-more-info:hover {
  text-decoration: underline;
}

/*# sourceMappingURL=index.1bb38df5.css.map */
